import { ImBin } from "react-icons/im";
import { db } from "../firebase/MyFirebase"
import { doc, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useEditData } from "../hooks/useEditData"
import Video from "./Video"
import Image from "./Image"

const Item = (props) => {

    const [ editing, setEditing ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ fieldValues, setFieldValues ] = useState(props.item);

    const { editData } = useEditData();

    useEffect(() => {
        setOpen(false);
        setEditing(false);
    },[props])

    useEffect(()=>{
        setFieldValues(props.item)
    },[])

    const close = () => {
        setEditing(false);
    }
    const toggleOpen = () => {
        if (open) {
            setOpen(false)
        } else if (open === false) {
            setOpen(true);
        }
    }
    useEffect(()=>{
        console.log(props.item)
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        editData(props.site, props.collection, props.item.id, fieldValues, props.fields, props.refresh)
        console.log("fields:" + JSON.stringify(fieldValues))
        console.log(props.fields);
        setEditing(false);
    }

    const deleteItem = async () =>{
        const confirmBox = window.confirm(
            "Are you sure you want to delete " + props.item.name +"? You will not be able to undo this."
        )
        if (confirmBox === true) {
            if(props.collection === "users"){
                
            }
            await deleteDoc(doc(db, "sites", props.site, props.collection, props.item.id));
        }
        props.refresh();
    }

    const editButton = () => {
        if (editing === false){
            setEditing(true);
        } else if (editing === true){
            setEditing(false);
        }
    }

    return (
        <li key={props.item.id} className="item-list">
            <div className="flex topbar">
                <h2 className="document-name" onClick={toggleOpen}>{props.item.name}</h2>
                {!!!props.chosing &&
                    <button className="edit" onClick={()=> props.chose(props.item.name, props.item.id)}>SELECT</button>
                }
                {props.chosing&&
                <div className="flex edit-buttons">
                    <button className="edit" onClick={editButton}><MdEdit></MdEdit></button>
                    <button className="delete" onClick={deleteItem}><ImBin></ImBin></button>
                </div>
                
                }
            </div>
            {!!!editing &&
                open && (
                    <span className="fields">
                        
                        {Object.keys(props.fields).map((field) =>(
                            <div className="displayFields">
                                <h4 className='description'>{field + ": "}</h4>
                                {props.fields[field].split(", ")[0] == "string" &&(
                                    <h4>{props.item[field]? ( <>{props.item[field]}</> ) : (<>Undefined</>)}</h4>
                                )}
                                {props.fields[field].split(", ")[0] == "nameId" &&(
                                    <h4>{props.item[field]? ( <>{props.item[field]}</> ) : (<>Undefined</>)}</h4>
                                )}
                                {props.fields[field].split(", ")[0] == "text" &&(
                                    <h4>{props.item[field]? ( <>{props.item[field]}</> ) : (<>Undefined</>)}</h4>
                                )}
                                {props.fields[field].split(", ")[0] == "listString" &&(
                                    <h4>{props.item[field]? ( <>{props.item[field]}</> ) : (<>Undefined</>)}</h4>
                                )}
                                {props.fields[field].split(", ")[0] == "int" &&(
                                    <h4>{props.item[field]? ( <>{props.item[field]}</> ) : (<>Undefined</>)}</h4>
                                )}
                                {props.fields[field].split(", ")[0] == "video" &&(
                                    <div className="video"><Video url={props.item[field]}></Video></div>
                                )}
                                {props.fields[field].split(", ")[0] == "image" &&(
                                    <div className="video"><Image url={props.item[field]} site={props.site}></Image></div>
                                )}
                                {props.fields[field].split(", ")[0].split(" ")[0] == "radio" &&(
                                    <h4>{props.item[field]? ( <>{props.item[field]}</> ) : (<>Undefined</>)}</h4>
                                )}
                            </div>
                        ))}
                    </span>
                )
            }
            {editing &&

                <form className="fields editing" onSubmit={handleSubmit}>
                    {Object.keys(props.fields).map((field) =>(
                        <label className="field">
                            
                            <h4>{field}</h4>
                            {props.fields[field].split(", ")[0] == "string" &&(
                                <input
                                required={field == "name"}
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "text" &&(
                                <textarea
                                rows="10"
                                cols="50"
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "listString" &&(
                                <textarea
                                rows="10"
                                cols="50"
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "int" &&(
                                
                                <input
                                type="number"
                                placeholder="1"
                                step="1"
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "video" &&(
                                <input
                                type = "file"
                                onChange={(e) =>  setFieldValues({...fieldValues, [field]: e.target.files[0]})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "image" &&(
                                <input
                                type = "file"
                                onChange={(e) =>  setFieldValues({...fieldValues, [field]: e.target.files[0]})}
                                />
                            )}
                            {props.fields[field].split(", ")[0].split(" ")[0] == "radio" &&(
                                <select value={fieldValues[field]} onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}>
                                    {props.fields[field].split(", ")[0].split(" ").filter((element, index) => { if(index > 0){return true;}} ).map((option) => (
                                            <option value={option}>{option}</option>
                                    ))}
                                </select>
                            )}
                        </label>
                    ))}
                    <div>
                        <button onClick={close} className="submit">Cancel</button>

                        <button className="submit" type="submit">Submit</button>
                    </div>
                    
                </form>
            }
            
            
        </li>
    )
}

export default Item
