import { useState } from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, setDoc, addDoc, doc, getDoc } from "firebase/firestore"; 
import { auth, db } from "../firebase/MyFirebase";

export const useAddData = () => {


    const addImage = async (file, site) => {
        const storage = getStorage();

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, site + '/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
            case 'storage/canceled':
                // User canceled the upload
                break;

            // ...

            case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                
            });
        }
        );
    }
    

    

    const addData = async (propsSite, propsCollection, fieldValues, fields) => {
        const newFieldValues = fieldValues
        for (const field in fieldValues){
            
            if (field in fields){
                if (fields[field].split(", ")[0] === "video"){
                    addImage(fieldValues[field], propsSite);
                    newFieldValues[field] = fieldValues[field].name;
                } else if (fields[field].split(", ")[0] === "image"){
                    addImage(fieldValues[field], propsSite);
                    newFieldValues[field] = fieldValues[field].name;
                }
            }
            
        }
        newFieldValues["createdAt"] = new Date().getTime();
        if (newFieldValues["ID"]){
            const docRef = doc(db, "sites", propsSite, propsCollection, newFieldValues["ID"]);
            await setDoc(docRef, 
                newFieldValues
            );
        } else {
            const docRef = await addDoc(collection(db, "sites", propsSite, propsCollection), 
                newFieldValues
            );
        }
        
    }
    return { addData }
}