import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useEffect, useRef, useState } from "react";

const Image = (props) => {
    const storage = getStorage();

    const [ videoURL, setVideoURL ] = useState();


    // Get the download URL
    useEffect(() =>{
        console.log("getting url "+props.url)
        if(props.url){
            
            const vidRef = ref(storage, props.site+ "/" + props.url)
            getDownloadURL(vidRef)
            .then((url) => {
                setVideoURL(url);
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.log("video error: " + error.message);
                switch (error.code) {
                case 'storage/object-not-found':
                    // File doesn't exist
                    console.log("image download error");
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    console.log("image download error");
                case 'storage/canceled':
                    // User canceled the upload
                    console.log("image download error");

                // ...

                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    console.log("image download error");
                }
            });
        }
    },[props.url])


    
    
    return (
        <div>
            <img src={videoURL} alt="" />
        </div>
    )
}

export default Image
