import { auth, db } from "../firebase/MyFirebase";
import { collection, getDocs, doc,  getDoc, query, orderBy, limit } from "firebase/firestore"; 
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../contexts/Auth";
import Item from "./Item"
import AddItem from "./AddItem";
import { Button } from "@mui/material";
import {ReactComponent as Logo} from '../images/logoWithName.svg';


import { NavLink, useHistory, useParams } from "react-router-dom";
import { list } from "firebase/storage";

const Editor = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [ items, setItems ] = useState([]);
    const [ fields, setFields ] = useState({});
    const [ links, setLinks ] = useState([]);
    const [ userDoc, setUserDoc] = useState({});
    const [ openedDocs, setOpenedDocs ] = useState();
    const [ siteName, setSiteName] = useState("")

    const [ addItem, setAddItem ] = useState(false);

    useEffect(()=>{
        refreshUserDoc()
    },[currentUser])



    useEffect (() =>{
        refresh();
    },[props])

    const add = () => {
        if (!addItem) {
            setAddItem(true);
        } else if (addItem) {
            setAddItem(false);
        }
        
    }
    useEffect(()=>{
        
    },[])
    
    useEffect(async()=>{


        const q = query(collection(db, "sites", userDoc.sites[0], "schema"));
        
        const querySnapshot = await getDocs(q);
        let list=[]
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
            list.push(doc.id)
        });
        setLinks(list);
    },[userDoc])

    
    const refreshUserDoc = async () => {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()){
            setUserDoc(docSnap.data());
            console.log(docSnap.data().sites)
            const docRef = doc(db, "sites", docSnap.data.sites[0]);
            const siteNameRef = await getDoc(docRef);
            if (siteNameRef.exists()){
                setSiteName(siteNameRef.data.siteAddress);
            }
        }
    }
      
    //Refresh runs when page loads or when refresh button is clicked
    const refresh = async () =>{
        //Get the documents to display for editing
        if (userDoc.sites){
            const workoutsRef = collection(db, "sites", userDoc.sites[0], props.collection)
            const q = query(workoutsRef, orderBy("createdAt", "desc"));
            
            const querySnapshot = await getDocs(q);
            let list = []
            querySnapshot.forEach((doc) => {
                console.log(doc.data())
                list.push(doc.data())
                list[list.length-1].id = doc.id;
            });
            setItems(list);
            console.log(items)


            //Get the schema for the current collection
            //The doc id should be the same as the name of the collection it is representing
            const docRef = doc(db, "sites", userDoc.sites[0], "schema", props.collection);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const initialObject = docSnap.data()
                let finalObject = {}
                let orders = []
                for (const property in initialObject) {
                    const propertyValues = initialObject[property].split(", ")
                    if (propertyValues.length > 1){
                        orders.push([property, propertyValues[1]])
                    }
                    orders.sort(function(a, b) {
                        return a[1] - b[1];
                    });
                }
                if (orders.length > 0){
                    for (const index in orders) {
                        const element = orders[index];
                        finalObject[element[0]] = initialObject[element[0]]
                    }
                } else {
                    finalObject = initialObject
                }
                setFields(finalObject)
                //console.log("fields: " +JSON.stringify(finalObject));
            } else {
                console.log("No such document!");
            }
        }
    }
    

    return (
        <div className="admin-page">
            <div className="flex logo-section">
                <a href="https://andrewswebdev.com"><Logo></Logo></a>
                {userDoc.sites
                    && <h1>{userDoc.sites[0]}</h1>
                }
                
            </div>
            <div className="flex second-section">
                
                <p>{currentUser.email}</p>
                <p className = "sign-out"onClick={()=>auth.signOut()}>Sign out</p>
            </div>
           
            <div className="main">
                {props.chosing &&
                <section className="selector">
                    {links.map((link, index)=>(
                        <NavLink key={index}to={"/"+link.replace(" ", "%20")} className="collection">{link}</NavLink>
                    ))}
                </section>
                }
                {links.map((link)=>(
                    link === props.collection &&
                        <section className="editor">
                            <span className="topbar">
                                <h1>{props.title}</h1>
                                <span>
                                    <button onClick={add} variant="constrained">Add{" "}</button>
                                    <button onClick={refresh} className="refresh"> Refresh</button>
                                </span>
                            </span>
                            <AddItem setAddItem = {(value)=>setAddItem(value)}addItem={addItem}fields={fields} collection={props.collection} refresh={refresh} site={userDoc.sites[0]}></AddItem>
                            <ul>
                                {items.length > 0 
                                    ? items.map((item) => (
                                        <Item fields={fields} item={item} collection={props.collection} chosing={props.chosing} chose={(workoutName, workoutId) =>props.chose(workoutName, workoutId)} refresh={refresh} site={userDoc.sites[0]}></Item> 
                                    ))
                                    : <li>No documents yet <button onClick={add} variant="constrained">Add</button></li>
                                }
                            </ul>
                        </section>
                ))
                    
                }
            </div>
            <div className="third-section">
                <h1 style={{marginBottom: "10px"}}>Site preview</h1>
                {userDoc.sites &&
                    <iframe src={siteName} className="sample-site"></iframe>
                }
                
            </div>
        </div>
    )
}

export default Editor
