import NavBar from "./components/NavBar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./components/Home";
import { AuthProvider } from "./contexts/Auth";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import Footer from "./components/Footer";
import Admin from "./components/Admin"
import Login from "./components/Login";
import Site from "./components/Site";
import "./styles/main.css"



///////APP


function App() {
  
  return (
    <div className="App">
      <AuthProvider>
        <Site>
          
        </Site>
      </AuthProvider>
    </div>
  );
}

export default App;
