import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPJYUB5sR_E8DqtXcxdGeFsU6fw3oEDVU",
  authDomain: "cms-awd.firebaseapp.com",
  projectId: "cms-awd",
  storageBucket: "cms-awd.appspot.com",
  messagingSenderId: "69656044503",
  appId: "1:69656044503:web:0d3305cf0a98a16346a192"
};


// Initialize Firebase
//const app = initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

export { db, auth };