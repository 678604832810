import { useAddData } from "../hooks/useAddData"
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {doc, getDoc} from "firebase/firestore";
import { db } from "../firebase/MyFirebase";

const AddItem = (props) => {

    
    const [ fieldValues, setFieldValues ] = useState({});

    const { addData } = useAddData();

    const [ needsId, setNeedsId] = useState(false);
    const [ id, setId ] = useState("");
    
    const close = () => {
        props.setAddItem(false);
    }

    useEffect(()=>{
        setNeedsId(checkNeedsID);
    },[props.fields])

    const checkNeedsID = () => {
        let found = false;
        for (let i = 0; i < Object.keys(props.fields).length; i++) {
            const field = Object.keys(props.fields)[i];
            if (props.fields[field].split(", ")[0] == "nameId"){
                found = true;
            }
        }
        console.log("CHECKID: "+found)
        return found;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let newFieldValues = fieldValues
        if (needsId){
            console.log(generateId(newFieldValues["name"]))
            newFieldValues["ID"] = await generateId(newFieldValues["name"]);
        }
        addData(props.site, props.collection, fieldValues, props.fields)
        props.setAddItem(false);
        props.refresh()
    }


    const throttle = (delay, fn) => {
        let inThrottle = false;
      
        return args => {
          if (inThrottle) {
            return;
          }
      
          inThrottle = true;
          fn(args);
          setTimeout(() => {
            inThrottle = false;
          }, delay);
        };
    };

    const generateId = async function(originalName){
        if (originalName != "" && needsId){
            const name = originalName.replace(/\s+/g, '-').toLowerCase();
            const docRef = doc(db, "sites", props.site, props.collection, name);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()){
                setId(name);
                console.log("HERE")
                return name;
            } else {
                let index = 1;
                let foundId = false;
                while (foundId == false){
                    const docRef = doc(db, "sites", props.site, props.collection, name+"-"+index);
                    const docSnap = await getDoc(docRef);
                    if (!docSnap.exists()){
                        foundId = true;
                        setId(name+"-"+index);
                        return name+"-"+index;
                    }
                    index = index + 1;
                }
            }
        }
    }
    

    return (
        <div className="new-item">
            
            {props.addItem &&
                <form className="fields" onSubmit={handleSubmit}>
                    {Object.keys(props.fields).map((field) =>(
                        
                        <label className="field">
                            <h4>{field}</h4>
                            {props.fields[field].split(", ")[0] == "string" &&(
                                <input
                                required={field == "name"}
                                value={fieldValues[field]? fieldValues[field] : ""}
                                onChange={
                                    field == "name"
                                    ? (e) =>{
                                        setFieldValues({...fieldValues, [field]: e.target.value});
                                        generateId(e.target.value);
                                        console.log("running from input")
                                    }
                                    : (e) => setFieldValues({...fieldValues, [field]: e.target.value})
                                }
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "text" &&(
                                <textarea
                                
                                rows="10"
                                cols="50"
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "listString" &&(
                                <textarea
                                
                                rows="10"
                                cols="50"
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "int" &&(
                                
                                <input
                                
                                type="number"
                                placeholder="1"
                                step="1"
                                value={fieldValues[field]}
                                onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "video" && (
                                <input
                                
                                type = "file"
                                onChange={(e) =>  setFieldValues({...fieldValues, [field]: e.target.files[0]})}
                                />
                            )}
                            {props.fields[field].split(", ")[0] == "image" && (
                                <input
                                
                                type = "file"
                                onChange={(e) =>  setFieldValues({...fieldValues, [field]: e.target.files[0]})}
                                />
                            )}
                            {props.fields[field].split(", ")[0].split(" ")[0] == "radio" &&(
                                <select value={fieldValues[field]} onChange={(e) => setFieldValues({...fieldValues, [field]: e.target.value})}>
                                    {props.fields[field].split(", ")[0].split(" ").filter((element, index) => { if(index > 0){return true;}} ).map((option) => (
                                            <option value={option}>{option}</option>
                                    ))}
                                </select>
                            )}
                            {props.fields[field].split(", ")[0] == "nameId" && (
                                <h5 style={{color: "darkgray"}}>{id}</h5>
                            )}
                        </label>
                    ))}
                    
                    {props.collection == "users" &&
                        <label className="field">
                            <h4>adminEmail</h4>
                            <input
                            required
                            type="email"
                            value = {fieldValues.adminEmail? fieldValues.adminEmail : ""}
                            onChange={(e) =>  setFieldValues({...fieldValues, ["adminEmail"]: e.target.value})}
                            />
                        </label>
                    }
                    {props.collection == "users" &&
                        <label className="field">
                            <h4>adminPassword</h4>
                            <input
                            required
                            type="password"
                            value = {fieldValues.adminPassword? fieldValues.adminPassword : ""}
                            onChange={(e) =>  setFieldValues({...fieldValues, ["adminPassword"]: e.target.value})}
                            />
                        </label>
                    }
                    <div>
                        <button onClick={close} className="submit">Cancel</button>

                        <button className="submit" type="submit">Submit</button>
                    </div>
                    
                </form>
            }
        </div>
    )
}

export default AddItem
