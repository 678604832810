import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useEffect, useRef, useState } from "react";

const Video = (props) => {
    const storage = getStorage();

    const [ videoURL, setVideoURL ] = useState();

    let played = false;

    const media = useRef();

    // Get the download URL
    useEffect(() =>{
        console.log("getting url "+props.url)
        if(props.url){
            
            const vidRef = ref(storage, "videos/" + props.url)
            getDownloadURL(vidRef)
            .then((url) => {
                setVideoURL(url);
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.log("video error: " + error.message);
                switch (error.code) {
                case 'storage/object-not-found':
                    // File doesn't exist
                    break;
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;

                // ...

                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
                }
            });
        }
    },[props.url])

    useEffect(() => {
        let elem = media.current
        if (props.playing){
            elem.play();
            
        } else if (props.playing === false){
            elem.pause();
        }
        return(()=>elem = null)
    },[props.playing, props.url])

    
    
    return (
        <div>
            <video ref = {media} src={videoURL} alt="loading" controls={(props.thumbnail != true)&& !(props.noControls == true)} preload="metadata" playsInline={(props.thumbnail != false)|| (props.noControls == true)} loop={props.loop}>

            </video>
        </div>
    )
}

export default Video
