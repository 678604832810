
import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { auth, db } from "../firebase/MyFirebase";
import { getDoc, doc } from "firebase/firestore"

import NavBar from "./NavBar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from "./Home";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Footer from "./Footer";
import Admin from "./Admin"
import Login from "./Login";

const Site = () => {
    const { currentUser } = useContext(AuthContext);
    const [ hasAppUser, setHasAppUser] = useState();
    const [loading, setloading] = useState(true)

    useEffect(() => {
        async function getCurrentAppUser(){

        if (!!currentUser){
            const authId = auth.currentUser.uid;
        
            const docRef = doc(db, "users", authId);
            let docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setHasAppUser(true);
            } else {
                setHasAppUser(false);
            }
            console.log(hasAppUser);
        }
        }
        getCurrentAppUser();
    });
  return(
    <div>
        <Router>
            
            
            {<Routes>
                <Route path="/:id" exact element={!!currentUser ? <Admin/> : <Login/>}></Route>


            </Routes>}
            <Footer></Footer>
        </Router>
    </div>
  );
};

export default Site;
