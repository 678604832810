import { useState } from "react";

import { auth } from "../firebase/MyFirebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export const useLogin = () => {
    const [error, setError] = useState(null);

    const login = (email, password) => {
        setError(null)
        signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
            console.log("user logged in:", res.user)
        })
        .catch((err) =>{
            let errorMessage = err.message
            if (errorMessage === "Firebase: Error (auth/user-not-found)."){
                errorMessage = "Invalid email.";
            } else if (errorMessage = "Firebase: Error (auth/wrong-password)."){
                errorMessage = "Incorrect password";
            }
            setError(errorMessage);
        })
    }
    
    return { error, login }
}