import { useContext, useEffect } from 'react'
import "../styles/admin.css"
import { Route, Routes, useParams } from 'react-router-dom'
import Editor from './Editor'
import { auth } from "../firebase/MyFirebase"
import { AuthContext } from "../contexts/Auth";

const Admin = () => {
    const { currentUser } = useContext(AuthContext);
    let { id } = useParams();
    return (
        
            <div >
                <Editor title={id} collection={id} chosing={true}></Editor>
            </div>
    )
}

export default Admin
