import { useState, useContext } from 'react'
import { useLogin } from "../hooks/useLogin"
import { auth, db } from "../firebase/MyFirebase";
import { doc, onSnapshot, query, collection, where } from "firebase/firestore";
import { Redirect } from 'react-router';
import { AuthContext } from "../contexts/Auth";import { updateCurrentUser } from '@firebase/auth';
import {ReactComponent as Logo} from '../images/logoWithName.svg';

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { error, login } = useLogin()
  const { currentUser } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
    
  }
  
  return (
    <div className='login-page'>
        <div className="flex logo-section">
            <Logo></Logo>
            <h2 className="cms-display">Content Management System</h2>
        </div>
        <div className="main">
        
        <form onSubmit={handleSubmit} className="login-form">
            <h2>Login</h2>
            <label>
            <h4>email</h4>
            <input
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            </label>
            <label>
            <h4>password</h4>
            <input
                required
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            </label>
            <button>log in</button>
            {error && 
            <h4>{error}</h4>
            }
            
        </form>

        </div>
    </div>
  )
}

